@import "../../styles/variables.scss";

.wrapper {
  border-radius: 18px 18px 0 0;
  background-color: $unnamed__color__FFFFFF;
  min-height: calc(75vh);
  padding-bottom: 3rem;
  box-shadow: 1px 1px 10px #00000033;
  &.white_shadow {
    box-shadow: 0px 0px 100px #d4d4d4;
  }
}
