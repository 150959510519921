@import "../../styles/variables.scss";

.user__level__page {
  padding-top: 3rem;
  background-color: $unnamed__color__d9dfff;
  background-color: var(--blue__color);
  position: relative;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
      transform: scale(1.5);
      // opacity: 0.8;
    }
  }
  .content {
    left: 0;
    top: 0;
    width: 100%;
    position: relative;
    z-index: 2;
  }
  &.blue {
    background-color: var(--blue__color);
  }
  &.green {
    background-color: var(--green__color);
  }
  &.purple {
    background-color: var(--purple__color);
  }
  .list__title {
    display: block;
    font-weight: 500;
    color: $unnamed__color__202020;
    margin-top: 15px !important;
    margin-bottom: 30px !important;
  }
  .user__levels {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    // background-color: teal;
    height: 100px;
    margin-top: 2rem;
    .level__badge__wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;
      margin-top: -1.5rem;
      position: relative;
      .level__name {
        // position: absolute;
        // color: #fff;
        // right: 9px;
        // font-size: 11px;
        display: block;
        font-weight: 600;
        min-width: 50px;
        text-align: center;
        opacity: 0.8;
        // width: 100%;
        // text-align: right;
      }
    }
  }

  .remaining {
    text-align: center;
    margin-top: 3rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 500;
    span {
      font-weight: 700;
      margin: 0 0.75rem;
      color: $unnamed__color__0acf83;
      font-size: 1.25rem;
    }
  }

  .upgrade__methods__list {
    row-gap: 24px;
  }
}
