@import "../../styles/variables.scss";

.user__transactions__page {
  .transactions__filter__btns {
    margin: 1.25rem 0;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    column-gap: 12px;
    overflow-x: scroll !important;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .transactions__set {
    padding: 1.25rem 0;
    .transactions__wrapper {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
    .set_date {
      color: $unnamed__color__9a9a9a;
      font-size: 12px;
    }
  }
}
