@import "../../styles/variables.scss";

.user__level__progress__bar {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  column-gap: 16px;
  width: 100%;

  .user__badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 11px;
    text-align: center;
    .level__name {
      font-weight: 500;
    }
    span {
      color: $unnamed__color__8650dc;
      font-size: 14px;
    }
  }
  .progress__bar__wrap {
    margin-top: -2rem;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    .progress__bar,
    .progress__completed {
      width: 100%;
      background-color: $unnamed__color__dddddd;
      height: 3px;
      border-radius: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .progress__completed {
      top: -1px;
      height: 5px;
      z-index: 3;
      background-color: $unnamed__color__09cf83;
      span {
        background-color: $unnamed__color__09cf83;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        display: block;
        min-width: 45px;
        border-radius: 1000px;
        margin-top: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0;
        line-height: 7px;
        position: absolute;
        right: 0;
        transform: translateX(50%) scale(0.9);
        top: -43px;
        &::after {
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          bottom: 0;
          transform: translateY(99%);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $unnamed__color__09cf83;
        }
      }
      &.ar {
        right: 0;
        span {
          right: auto;
          left: 0;
          transform: translateX(-50%) scale(0.9);
        }
      }
    }
    .user__XP {
      position: absolute;
      bottom: -45px;
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      text-align: center;

      color: $unnamed__color__707070;
      span {
        color: $unnamed__color__09cf83;
      }
    }
  }
  &.gold {
    .progress__completed {
      background-color: $unnamed__color__fcb042 !important;
    }
    .user__XP {
      span {
        color: $unnamed__color__fcb042;
      }
    }
    .progress__completed {
      top: -1px;
      height: 5px;
      z-index: 3;
      background-color: $unnamed__color__09cf83;
      span {
        color: rgb(77, 77, 77);
        background-color: $unnamed__color__fcb042;
        &::after {
          border-top: 5px solid $unnamed__color__fcb042;
        }
      }
    }
  }
}
