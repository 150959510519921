.rank__row {
  width: 100%;
  height: 70px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #3b4b7e;
  &.placeholder {
    opacity: 0.3;
  }
  &.blue {
    background-color: #3b4b7e;
  }
  &.green {
    background-color: #60a77c;
  }
  &.purple {
    background-color: #ad5faf;
  }
  &.yellow {
    background-color: #c19c23;
  }
  .coins {
    display: flex;
    align-items: center;
    column-gap: 6px;
    span {
      font-weight: 700;
      font-size: 14px;
    }
  }
  .achievements {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }
  .rank__info {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .rank__user__avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        object-position: center;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    .rank__username {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
