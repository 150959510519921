.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  padding-bottom: 0.5rem;
  position: relative;
  .title {
    text-transform: capitalize;
    font-size: 18px;
  }
  .btn__wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-35%);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:lang(ar) {
      left: auto;
      right: 0;
      .arrow_icon {
        transform: rotate(180deg) !important;
      }
    }
    .back_btn {
      font-size: 18px;
    }
  }
}
