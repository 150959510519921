:root {
  --main__font__family: "cairo";
  --blue__gradient__color: linear-gradient(#50b3e5, #8f4398);
  --green__gradient__color: linear-gradient(#00a447, #478e9a);
  --purple__gradient__color: linear-gradient(#a40078, #734a8a);
  --yellow__gradient__color: linear-gradient(#a49f00, #585e21);
  --white__color: #fff;
  --gold__color: #ffcb81;
  --gold__color__transparent: #ffcb818f;
  --blue__color: #1350d0;
  --green__color: #128837;
  --purple__color: #8b0c4c;
  --yellow__color: #463305;
}
