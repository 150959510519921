.top__ranked__wrapper {
  position: relative;
  width: 100%;
  margin-top: -0.75rem;
  .top__border {
    position: absolute;
    left: -5%;
    top: -170%;
    width: 110% !important;
    z-index: 1;
  }
  .cards__wrapper {
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    margin-top: 5.5rem;
    padding: 0 4px;
    display: flex;
    column-gap: 10px;
  }
  .no__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 700;
    row-gap: 1rem;
    text-transform: capitalize;
    .icon {
      font-size: 5rem;
    }
  }
}
