@import "../../styles/variables.scss";

.user__badges__page {
  background-color: $unnamed__color__d9dfff;
  .badges__container {
    display: flex;
    column-gap: 13px;
  }
  .col {
    padding: 5px;
  }
}
