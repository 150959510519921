@import "../../styles/variables.scss";

.user__info__header {
  padding: 2rem 0;
  background-color: $unnamed__color__d9dfff;
  .info__wrapper {
    display: flex;
    align-items: center;
    column-gap: 15px;
    .user__avatar__wrapper {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background-color: rgb(230, 230, 230);
      box-shadow: 1px 1px 10px #00000057;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        z-index: 1;
      }
    }
    .user__details {
      .current__level {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
        img {
          width: 55px;
        }
        span {
          position: absolute;
          right: 0;
          margin-right: 7px;
        }
      }
      .user__name {
        color: $unnamed__color__202020;
        font-weight: 500;
        font-size: 20px;
      }
      .user__level {
        // background-color: $unnamed__color__1abcfe;
        font-size: 11px;
        color: $unnamed__color__FFFFFF;
        // border-radius: 100px;
        // padding: 0.15rem 0.75rem;
        display: inline-flex;
        font-weight: 600;
        // margin-top: 0.5rem;
      }
    }
  }
  &.no_bg {
    background: transparent;
    .user__avatar__wrapper {
      border: 3px solid #fff;
    }
    .user__details {
      .user__name {
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .user__badges__actions {
    display: flex;
    margin-top: 1rem;
    column-gap: 8px;
    .badge__btn {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px dashed $unnamed__color__0acf83;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $unnamed__color__0acf83;
      img {
        width: 100%;
        object-position: center;
      }
      &:disabled {
        color: $unnamed__color__9a9a9a;
        border: 1px dashed $unnamed__color__9a9a9a;
        cursor: not-allowed;
      }
    }
  }
}
