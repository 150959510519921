.filter__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  .filter__list {
    background-color: #ffffff42;
    border-radius: 1000px;
    overflow: hidden;
    height: 45px;
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    .list__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      text-wrap: nowrap;
      min-width: 90px;
      border-radius: 50px;
      &.blue {
        &.active {
          background-color: var(--blue__color);
        }
      }
      &.green {
        &.active {
          background-color: var(--green__color);
        }
      }
      &.purple {
        &.active {
          background-color: var(--purple__color);
        }
      }
      &.yellow {
        &.active {
          background-color: var(--yellow__color);
        }
      }
      &.active {
        // background-color: var(--blue__color);
        .filter__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2222px;
          height: calc(100% - 8px);
          width: calc(100% - 8px);
          border: 1px solid var(--gold__color);
          span {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 10px);
            border: 1px solid var(--gold__color__transparent);
            border-radius: 2222px;
          }
        }
      }
    }
  }
}
