@import "../../styles/variables.scss";

.package__card {
  background-color: $unnamed__color__f7f7f7;
  border-radius: 16px;
  height: 142px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // overflow: hidden !important;
  margin: 7px;
  .buy__package__btn {
    background-color: #fcb042;
    height: 42px;
    color: $unnamed__color__FFFFFF;
    text-align: center;
    font-size: 15px;
    border-radius: 0 0 16px 16px;

    box-shadow: 1px 1px 10px #00000025;
  }
  .package__coins__number {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    .pakage__quantity {
      margin-top: 6px;
      font-size: 18px;
      color: #fcb042;
      font-weight: 600;
    }
  }
}
