.rank__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .rank__bg {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    // width: 100%;
  }
  .card__content {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -2.75rem;
    padding: 0 1.2rem;
    .rank__frame {
      width: 90px;
      height: 90px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .user__avatar {
        position: absolute;
        z-index: 1;
        width: 66px;
        height: 66px;
        // background-color: rgb(255, 0, 0);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          background-color: #fff;
        }
      }
      .frame {
        width: 100%;
        z-index: 2;
        position: absolute;
        img {
          width: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
    }
    .user__name {
      font-weight: 700;
      font-size: 11px;
      margin-bottom: -5px;
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .achievements {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 2px;
    }
    .coins {
      margin-top: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      .value {
        margin-bottom: 0;
        line-height: 0;
        font-weight: 700;
        font-size: 14px;
        opacity: 0.7;
        // color: #b8860b;
      }
    }
  }
}
