@import "../../styles/variables.scss";

.nav__bar {
  border-bottom: 1px solid $unnamed__color__dddddd;
  padding-top: 0.75rem;
  .nav__btn {
    padding: 15px;
    font-size: 15px;
    color: $unnamed__color__9a9a9a;
    text-transform: capitalize;
    // width: 80px !important;
    height: 40px !important;
    &.active {
      border-bottom: 2px solid $unnamed__color__8650dc;
      color: $unnamed__color__8650dc;
      font-weight: 600;
    }
  }
}
