@import "./variables.scss";

*,
*:after,
*:before {
  padding: 0;
  margin: 0;
}

body {
  font-family: $main__font__family, var(--main__font__family);
  // overflow-x: hidden;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

button {
  all: unset;
  cursor: pointer;
}

ul {
  all: unset;
  display: flex;
  flex-direction: column;
  list-style: none;
}
a {
  text-decoration: none;
}
