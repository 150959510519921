@import "../../styles/variables.scss";

.filter__btn__style {
  color: $unnamed__color__17004f;
  font-size: 14px;
  height: 36px;
  border: 1px solid $unnamed__color__dddddd;
  border-radius: 30px;
  // padding: 0 1.2rem;
  white-space: nowrap;
  height: 39px !important;
  min-width: 160px !important;
  text-align: center;
  &.active {
    background-color: $unnamed__color__8650dc;
    color: $unnamed__color__FFFFFF;
  }
}
