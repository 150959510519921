@import "../../styles/variables.scss";

.badge__card {
  height: 127px;
  background-color: $unnamed__color__f7f7f7;
  border-radius: 16px;
  // min-width: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 11px;
  margin: 8px 0;
  width: auto;
  cursor: pointer;
  height: 127px !important;
  .badge__name {
    font-size: 14px;
    color: #080e23;
  }
  &.disabled {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}
