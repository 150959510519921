.loading__wrapper__ {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .blue {
    color: #a7bdff;
  }
  .green {
    color: #9bffc3;
  }
  .purple {
    color: #fd92ff;
  }
  .yellow {
    color: #c19c23;
  }
}
