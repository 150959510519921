@import "../../styles/variables.scss";

.badge__details__page {
  color: $unnamed__color__FFFFFF;
  min-height: 100vh;
  background-color: $unnamed__color__2d2d2d;
  padding: 4rem 0;
  .svga__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    canvas {
      width: 170px;
    }
    img {
      width: 170px;
    }
    &.disabled {
      canvas {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }
  .badge__icon__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 230px;
    .blur__layer {
      position: absolute;
      left: 50%;
      top: 0;
      width: 204px;
      height: 204px;
      transform: translateX(-50%);
      border: 1px solid $unnamed__color__707070;
      background: transparent
        radial-gradient(closest-side at 50% 50%, #8bf8fc 0%, #dbfefd 100%) 0% 0%
        no-repeat padding-box;
      border: 1px solid $unnamed__color__707070;
      opacity: 0.2;
      filter: blur(50px);
      z-index: 1;
    }
    .badge__icon {
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      position: absolute;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 204px;
    }
  }
  .badge__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      color: $unnamed__color__fcb042;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .progress__bar__wrapper {
    margin-top: 3rem;
    padding: 0.5rem 1.5rem;
  }
  .badges__exp {
    padding: 4.5rem 1.5rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    justify-content: center;
    .wear__badge__btn {
      font-size: 16px;
      font-weight: 500;
      margin: 2rem 0;
      padding: 0.75rem 2.75rem;
      border-radius: 1000px;
      color: $unnamed__color__fcb042;
      border: 3px solid $unnamed__color__fcb042;
      width: 150px !important;
      height: 25px !important;
      &.active {
        background-color: $unnamed__color__fcb042;
        color: #2d2d2d;
        font-weight: 600;
      }
    }
    .badge__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      position: relative;
      &:not(:first-of-type) {
        &::after {
          content: "";
          position: absolute;
          right: 122%;
          top: calc(50% - 30px);
          width: 11vw;
          height: 4px;
          background: linear-gradient(to right, #2d2d2d, #ddd);
          border-radius: 1000px;
        }
      }
      .exp {
        display: flex;
        align-items: center;
        column-gap: 6px;
        .quantity {
          color: $unnamed__color__fcb042;
          font-weight: 500;
        }
      }
    }
  }
}
