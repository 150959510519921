@import "../../styles/variables.scss";

.upgrade__method__card {
  display: flex;
  justify-content: space-between;
  height: 55px;
  column-gap: 20px;
  .details {
    display: flex;
    column-gap: 16px;
    .icon__wrapper {
      height: 60px;
      width: 60px;
      background-color: $unnamed__color__f7f7f7;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card__facts {
      margin: 0.2rem 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      p {
        color: $unnamed__color__080E23;
        font-weight: 500;
      }
      span {
        color: $unnamed__color__707070;
        font-size: 13px;
      }
    }
  }
  .quantity {
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-weight: 500;
  }
}
