.user__rank {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #2d489b;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 1px 1px 10px #00000034;
  // justify-content: space-between;
  &.blue {
    background-color: #2d489b;
  }
  &.green {
    background-color: #2b6341;
  }
  &.purple {
    background-color: #401441;
  }
  &.yellow {
    background-color: #a44404;
  }
  .container {
    max-width: 385px;
    padding: 0 0.25rem;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .coins {
      display: flex;
      align-items: center;
      column-gap: 6px;
      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
    .achievements {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5px;
    }
    .rank__info {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .rank__user__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          object-position: center;
          object-fit: cover;
          height: 100%;
          border-radius: 50%;
          background-color: #fff;
        }
      }
      .rank__username {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .no__data {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    text-transform: capitalize;
    .icon {
      font-size: 2rem;
    }
  }
}
