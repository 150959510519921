.uesr__wallet__page {
  .user__balance__row {
    margin: 2rem 0;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  .card__wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
}
