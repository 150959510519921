.app__container {
  color: #fff;
  // max-width: 768px;
  margin: auto;
  min-height: 120vh;
  position: relative;
  overflow: hidden;
  &.blue__theme {
    background: var(--blue__gradient__color);
  }
  &.green__theme {
    background: var(--green__gradient__color) !important;
  }
  &.purple__theme {
    background: var(--purple__gradient__color);
  }
  &.yellow__theme {
    background: var(--yellow__gradient__color) !important;
  }
  .container {
    max-width: 385px;
  }
  .img__bg__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 120vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100%;
    }
  }
  .app__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 120vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
  }
}
